body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.process-flow-container {
  position: fixed;
  top: 0;
  left: 220px;
  right: 0px;
  /* width: calc(100vw-100px); */
  height: 100vh;
  z-index: -1; /* To place it in the background */
  /* background-color: #f5eda8ff;   */
  background-color: #f5f4f4;
}

h1{
  text-align: left;
  padding-left: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* background-color: #f5eda8ff; */
  background-color: white;
  border-bottom: 2px solid black;
}

.header h1 {
  margin: 0;
  font-size: 32px;
  color: #333;
}

/* CSS for the dropdowns */
.dropdowns {
  display: flex;
  gap: 20px;  
}

.dropdowns div {
  flex: 1;
  text-align: left;
}

.treeview-sidebar{  
  width: 220px;
  height: 100%;  
  position: fixed;  
  /* background-color: #f5eda8ff; */
  /* background-color: rgb(228, 228, 238); */
  background-color: #1b1724;
  /* border-right: 2px solid black;     */
  text-align: left;
}

.treeview{
  /* padding-top: 10px; */
  /* position: fixed; */
  /* width: 200px;
  height: 100%;     */
  text-align: left;    
}

.tree-asm .MuiTreeItem-label
{  
  font-weight: 700;
}

.tree-comp .MuiTreeItem-label
{
  font-weight: 300;
}

.MuiTreeItem-content.Mui-selected{    
  background-color: #5925DC !important;
}

.treeview-sidebar .MuiTreeItem-label{
  color: white;
}

.modal-area{
  background-color: rgb(228, 228, 238);
  border-radius: 10px;
  padding: 1px 24px 24px;  
  margin-bottom: 24px;  
}

.centered{
  position: absolute;
  top: 50%;
  left: 50%;
}

.react-flow__attribution{
  display: none;
}

.custom-header{
  font-weight: 900;    
}

.shadow{
  box-shadow: 0px 0px 25px rgba(0,0,0,0.2)
}

.shadow:hover{
  box-shadow: 0px 10px 25px rgba(0,0,0,0.2)
}

h4{
  font-size: 18px;
}

.totalImpacts{
  width: 200px;
  background-color: #5925DC11;
  padding: 0px 10px;
  border-radius: 15px;
  border: 2px solid #5925DC;
  color: #5925DC;
}

.tip-overlay {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* left: 0; */
  /* transform: translate(100%, 0%); */
  /* background-color: rgba(240, 118, 19, 0.8); */
  top: 0;
  margin-left: 10px;
  left: 100%;
  background-color: #FF9A5C;
  color: white;
  padding: 10px;
  border-radius: 10px;
  z-index: 9999;
  display: flex;
  align-items: center; 
  font-size: 16px;
  color: #804623;
}

.tip-overlay > div {
  margin-right: 20px; /* Add some space between elements */
}
.tip-overlay > div:last-child {
  margin-right: 0; /* Remove margin from last element */
}


.tip-number-container{
  /* background-color: #5925DC;   */
  border: 1px solid #804623;;
  /* color: white; */
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  text-align: center;  
  display: flex; /* Use flexbox to center the number */
  justify-content: center; /* Center the number horizontally */
  align-items: center; /* Center the number vertically */
}

.tip-button{
  background-color: #ffb78b;
  padding: 5px 10px 5px 10px;  
  height: auto;
  border-radius: 5px;
  color: #804623;
  font-weight: bold;
}

.tip-button:hover{
  background-color: #fac3a0;
  cursor:pointer;
}

.tip-text{  
  white-space: nowrap;
}

.tip-container{
  position: relative;
  display: flex;
}


